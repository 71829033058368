import config, { ServiceType } from '../../app.config';
import themes from '../../theme/themes';

export default {
  title: 'Личный кабинет партнёра',
  theme: themes[config.service === ServiceType.Spitch ? 'spitch' : 'bss'],
  agreementLink: '/shared-pdf/policy.pdf',

  //https://www.i18next.com/translation-function/formatting
  money: {
    locale: 'ru-RU',
    currency: 'RUB',
    currencySign: '₽',
  },

  intlLocale: 'ru-RU', //de-DE,en-US

  socialNetworks: {
    twitter: 'https://twitter.com/bssyscom',
    facebook: 'http://www.facebook.com/bssyscom',
    telegram: 'https://t.me/+3XsrS3yzwURhNGQy',
    youtube: 'https://www.youtube.com/user/bssyscom',
    vk: 'https://vk.com/bssys',
  },

  phoneInputMask: '+# (###) ###-##-##',
  phoneDisplayMask: '+# (###) ###-##-##',

  langs: {
    ru: 'Русский',
    gb: 'Английский',
    de: 'Немецкий',
  },

  materialGroupRestriction: {
    users: 'Выбранные пользователи',
    everyone: 'Без ограничений',
    roles: 'Выбранные роли',
  },

  page: {
    cities: {
      newName: 'Пожалуйста, введите новое имя',
      headline: 'Добавить',
      update: 'Изменить',
      delete: 'Удалить',
    },
    docs: {
      addProduct: 'Добавить продукт',
      addDoc: 'Добавить релиз',
      addRelease: 'Добавить релиз',
      createdAt: 'Время добавления',
      open: 'Открыть',
      delete: 'Удалить',
      deleteCheck: 'Вы уверены?',
      edit: 'Редактировать',
      releaseName: 'Продукт',
      noRowsFounds: 'Тут ничего нет',
      all: 'Все продукты',
      lang: 'Язык',
      editProduct: 'Редактировать продукт',
    },
    software: {
      addProduct: 'Добавить продукт',
      addDoc: 'Добавить релиз',
      addRelease: 'Добавить релиз',
      createdAt: 'Время добавления',
      open: 'Открыть',
      delete: 'Удалить',
      deleteCheck: 'Вы уверены?',
      edit: 'Редактировать',
      releaseName: 'Продукт',
      noRowsFounds: 'Тут ничего нет',
      all: 'Все продукты',
      lang: 'Язык',
      editProduct: 'Редактировать продукт',
    },
    loginAgreement: {
      forContinueYouShouldAccept:
        'Для продолжения работы с сервисом, необходимо принять',
      acceptAgreement: 'политику конфиденциальности',
    },

    education: {
      info: 'Уважаемые партнеры, для получения статуса и сертификата о прохождении обучения вам необходимо пройти тестирование',
      result: 'О результатах обучения мы сообщим по e-mail.',
      question: 'Задать вопрос',
      note: 'Рекомендуем повторить материал о продуктах BSS перед тестированием',
      button: {
        engineers: 'Тест для инженеров',
        managers: 'Тест для менеджеров по продажам',
        materials: 'Материалы о продуктах',
      }
    },

    errorHandler: {
      insufficientPermissions: 'Недостаточно прав',
      notFound: 'Страница не найдена',
      serverError: 'Ошибка сервера',
      headToIndex: 'Перейти на главную',
      errorText: 'Ошибка',
    },

    partnerEdit: {
      headline: 'Редактирование',
      city: 'Город',
      mobilePhone: 'Мобильный тел.',
      workPhone: 'Рабочий тел.',
      isActive: 'Активен',
      manager: 'Менеджер BSS',

      save: 'Сохранить профиль',
    },

    login: {
      headlinePersonal: 'Личный кабинет партнера',
      forgotPassword: 'Забыл пароль',
    },

    projectEdit: {
      choose: 'Выберите',
      infoHeadline: 'Данные по проекту',
      statusChangeReasonField: 'Причина изменения статуса',
      department: 'Департамент',

      statusField: 'Статус',
      customerField: 'Заказчик',
      subjectField: 'Тема проекта',
      requestedAmountField: 'Прогн. сумма',
      actualAmountField: 'Факт. сумма',
      amountField: 'Сумма',
      dealAtField: 'Дата подачи',
      contractField: 'Контактное лицо',
      dateCreatedField: 'Дата создания',
      documentationDropZone: 'Конкурсная документация',
      comment: 'Комментарий',

      saveProject: 'Сохранить проект',
      addProject: 'Добавить проект',
    },

    registration: {
      notFilled: 'Не заполнено:',
      acceptAgreement: 'Я согласен с политикой конфиденциальности',
      manager: 'Менеджер BSS',
      partnerName: 'Название партнера',
      headline: 'Регистрация партнера',

      workPhone: 'Рабочий тел.',
      mobilePhone: 'Мобильный тел.',
      partnerSuccessfullyCreated: 'Партнер создан',

      fieldName: 'Название партнера',
      fieldCity: 'Город',
      fieldTaxId: 'ИНН',
      fieldFullName: 'ФИО',
      fieldPosition: 'Должность',
      fieldEmail: 'E-Mail',
      fieldPersonalPhone: 'Мобильный тел.',
      fieldWorkPhone: 'Рабочий тел.',
      fieldPolicy: 'Политика конфендциальности',
      fieldCaptcha: 'Капча',
      fieldCountries: 'Страны',
    },
    news: {
      insertLinkPrompt: 'Вставьте ссылку на изображение:',

      haveNoEvents: 'Мероприятий пока нет',

      subscribeNewsletter: 'Подписаться',
      followedSuccessful: 'Подписка оформлена',
      readNewsHeadline: 'Читайте свежие новости BSS',

      otherNews: 'Другие новости',

      event: 'Мероприятие',
      showAll: 'Показать все',

      newsNotFound: 'Новости не найдены',
      newsDoesntExists: 'Новостей пока нет',

      addNews: 'Добавить новость',
      allNews: 'Все',
      onlyNews: 'Новости',
      onlyEvents: 'Мероприятия',

      editingMode: 'Редактирование {{what}}',
      addingMode: 'Добавление {{what}}',

      modeNews: 'новости',
      modeEvent: 'мероприятия',
    },

    newsAdd: {
      saveAndClose: 'Сохранить и закрыть',
      unpublish: 'Снять с публикации',

      event: 'мероприятие',
      news: 'новость',
      toPublish: 'Опубликовать {{what}}',

      multipleNews: 'Новости',
      multipleEvents: 'Мероприятия',

      publishDate: 'Дата публикации',
      fieldText: 'Текст',
      fieldPicture: 'Изображение',
      fieldLid: 'Лид',
      fieldTitle: 'Заголовок',
      fieldDateRange: 'Период', //periods :)
    },

    restorePassword: {
      restoreButton: 'Восстановить',
      rememberedPasswordBack: 'Вспомнил пароль',
      mailWasSent: 'Письмо отправлено',
      restorePasswordHeadline: 'Восстановление пароля',
    },

    material: {
      addFile: 'Добавить материал',
      editCategories: 'Редактировать категории',
      editGroups: 'Редактировать группы',

      type: {
        sales: 'Для продаж',
        tech: 'Технические',
        ads: 'Рекламные',
        partnership: 'Партнерская программа',
        cases: 'Кейсы',
      },

      table: {
        name: 'Имя файла',
        size: 'Размер',
        createdAt: 'Дата загрузки',
        author: 'Автор',
        isActual: 'Актуально',
      }
    },

    projects: {
      tableCity: 'Страна',
      nothingFound: 'Ничего не найдено',
      noRequestsFound: 'Заявок пока нет',
      resetFilter: 'Очистить все',
      makeSureInputIsCorrect: 'Убедитесь, что введенные данные верны',
      queryTermNotFound: '{{query}} - не найдено',
      total: 'Итого: {{count}} контракт. на сумму ',
      tableStatus: 'Статус',
      tablePartner: 'Партнер',
      tableCustomer: 'Заказчик',
      tableCreatedAt: 'Дата создания',
      tableSubject: 'Тема проекта',
      tableDealAt: 'Дата подачи',
      tableAmount: 'Сумма контракта',
      tableComment: 'Комментарий',
      newProject: 'Новый проект',
      expireAt: 'Истекает',
      actualAmount: 'Фактическая сумма',
    },

    partners: {
      navActive: 'Активные',
      navArchive: 'В архиве',
      navRequest: 'Заявки',

      addPartner: 'Добавить партнера',
      addPartnerMobile: '+ Партнера',

      noPartnersInArchive: 'Партнеров в архиве нет',
      noPartnersAtAll: 'Партнеров пока нет',

      sendPartnerToArchive: 'Отправить партнера в архив',
      acceptTheRequest: 'Одобрить заявку партнера',

      restorePartner: 'Восстановить',
      partnerContract: 'Контакты Партнера',

      inWork: 'Активно',
      signed: 'Отклонено',
      unsigned: 'Закрыто',

      partnerSentToArchive: 'Партнер отправлен в архив',
      partnerRestored: 'Партнер восстановлен в активные',
    },

    partnerProfile: {
      documents: 'ДОКУМЕНТЫ',
      revenueServiceNumber: 'ИНН',
      registrationDate: 'Регистрация',
      companyContractInfo: 'КОНТАКТЫ КОМПАНИИ',
      managerHeadline: 'МЕНЕДЖЕР BSS',

      inAmount: 'на сумму',
      contractsMention: 'контракт.',
      noCountriesFollowed: 'Нет привязанных стран',
      noProjects: 'У партнера нет проектов',
    },

    feedback: {
      title: 'Форма обратной связи',
      label: 'Обратная связь, вопросы, пожелания',
      button: {
        send: 'Отправить',
      },
      error: "Запрос не отправлен",
      success: "Запрос отправлен",
    }
  },

  component: {
    projectHistory: {
      headline: 'История статусов',
      system: 'Система',
      noHistory: 'Записей нет',
    },

    managerContractCreation: {
      headline: 'Менеджеры',
    },
    managerUserEmployeeCreation: {
      headline: 'Сотрудники',
    },

    projectFollow: {
      headline: 'Подписка на проекты',
      subjectsHeadline: 'Подписка на уведомления',
      noSubscribes: 'Нет подписок',
      partnerRegister: 'Регистрация партнера',
    },

    userProfileCard: {
      manager: 'Менеджер БСС',
    },

    managerModal: {
      headline: 'Добавление менеджера',
    },
    managerUserEmployeeModal: {
      headline: 'Добавление сотрудника',
    },

    newsCard: {
      hide: 'Скрыть новость',
    },

    projectsSearchbar: {
      filtersLable: 'Фильтры',
      clear: 'Очистить все',
      status: 'Статус',
    },

    employeeSearchbar: {
      addEmployee: 'Добавить сотрудника',
      clear: 'Очистить все',
      filtersLable: 'Фильтры',

      status: 'Статус',
    },

    projectCard: {
      department: 'Департамент',
      statusDescription: 'Описание статуса',
      requestedAmount: 'Прогнозируемая сумма',
      actualAmount: 'Фактическая сумма',
      contactPerson: 'Контактное лицо',
      partner: 'Партнер',
      dealAt: 'Дата подачи',
      createdAt: 'Создано',
      comment: 'Комментарий',
    },

    partnerStatsMobile: {
      atAll: 'всего',
      inWork: 'в работе',
      inMonth: 'за месяц',
      contractsTotal: 'сумма контрактов',

      pluralPartner: 'партнер.',
      pluralContract: 'контракт.',
      partners: 'партнеров',
    },

    projectStats: {
      inWork: 'в работе',
      inMonth: 'за месяц',
      atAll: 'всего',

      contractsTotal: 'сумма контрактов',
      pluralPartner: 'партнер.',
      pluralContract: 'контракт.',
    },

    dropZone: {
      dropFileHere: 'Перетащите файл в эту область или',
      orChooseManual: 'загрузите с компьютера',
      fileRequirement: 'Максимальный размер 20 МБ.',
      wrongSizeError: '{{name}} имеет недопустимый размер {{size}} мб',

      uploadButton: 'Загрузить',
    },

    addFileDrawer: {
      createHeadline: 'Добавление материала',
      section: 'Раздел',
      category: 'Категория',
      group: 'Группа',
      url: 'Ссылка',

      typeFile: 'Файл',
      typeUrl: 'Ссылка',

      name: 'Имя',
    },

    editFileDrawer: {
      editHeadline: 'Редактирование материала',
      section: 'Раздел',
      category: 'Категория',
      group: 'Группа',
      publicId: 'Публичный ID',

      url: 'Ссылка',

      typeFile: 'Файл',
      typeUrl: 'Ссылка',

      publicIdHintEmpty: 'Материал не будет иметь публичной ссылки',
      publicIdHintFilled: 'Материал будет иметь публичную ссылку',

      actual: 'Актуальный',
    },

    categoryCreateDrawer: {
      categoryName: 'Название',
      headline: 'Добавление категории',
      editCategories: 'Редактирование категорий',
    },

    materialGroupDrawer: {
      editCategory: 'Редактировать категорию',
      editGroup: 'Редактировать группу',
      editGroups: 'Редактирование групп',
      createGroup: 'Добавление группы',

      category: 'Категория',
      section: 'Раздел',
      name: 'Название',

      canAdd: 'Добавление',
      canView: 'Просмотр',

      canAddAccessType: 'Тип прав добавления',
      canViewAccessType: 'Тип прав просмотра',
    },

    statusFollow: {
      headline: 'В статусах',
      inCountries: 'В странах',
    },

    countryFollow: {
      headline: 'Подписка на проекты',
    },

    changePassword: {
      headline: 'Изменение пароля',
      currentPassword: 'Текущий пароль',
      newPassword: 'Новый пароль',
      repeatNewPassword: 'Новый пароль еще раз',
      changePassword: 'Изменить пароль',

      passwordChanged: 'пароль изменен',
      passwordCantBeChanged: 'Поле не может быть пустым',
      passwordsNotEqual: 'Пароли не совпадают',
    },

    addCityDrawer: {
      createHeadline: 'Добавить страну',
      title: 'Название',
    },

    addDocumentDrawer: {
      createHeadline: 'Добавить релиз',
      editHeadline: 'Редактировать релиз',
      title: 'Заголовок',
      url: 'Ссылка',
      pdfUrl: 'Ссылка на PDF',
      release: 'Продукт',
      lang: 'Язык',
      description: 'Описание',
    },

    addProductDrawer: {
      sort: 'Номер сортировки',
      createHeadline: 'Добавить продукт',
      title: 'Название',
      edit: 'Сохранить',
      editHeadline: 'Изменить продукт',
    },
  },

  button: {
    delete: 'Удалить',
    add: 'Добавить',
    login: 'Вход',
    save: 'Сохранить',
    cancel: 'Отмена',
    edit: 'Редактировать',
    editProfile: 'Редактировать профиль',
    editProject: 'Редактировать проект',
    register: 'Зарегистрировать',
    restore: 'Восстановить',
    continue: 'Продолжить',
    externalLink: 'ВНЕШНЯЯ ССЫЛКА',
  },

  words: {
    noEmployees: 'Сотрудников нет',
    employees: 'Сотрудники',
    exportEmployees: 'Экспорт cотрудников',
    allCities: 'Все страны',
    city: 'Страна',
    taxId: 'ИНН',
    fullNameAbbr: 'ФИО',
    email: 'E-Mail',
    password: 'Пароль',
    phone: 'Телефон',
    partner: 'Партнер',
    position: 'Должность',
    firstAndLastNames: 'Имя и фамилия',
    search: 'Поиск',
    allPartners: 'Все партнеры',
    projects: 'Проекты',
    title: 'Название',
    reset: 'Сбросить',
    countries: 'Страны',
    accept: 'Принять',
    role: 'Роль',
    createdAt: 'Дата создания',
  },

  role: {
    all: 'Все роли',
    partner: 'Партнер',
    employee: 'Сотрудник',
    admin: 'Администратор',
    customer: 'Клиент',
    partner_admin: 'Администратор партнеров',
    documentation_admin: 'Команда док-ов',
    end_customer: 'Конечный клиент',
    internal_team: 'Внутренняя команда',
  },

  header: {
    docs: 'Документация',
    project: 'Проект',
    projects: 'Проекты',
    partners: 'Партнеры',
    materials: 'Материалы',
    companyProfile: 'Профиль компании',
    news: 'Новости',
    events: 'Мероприятия',
    employees: 'Сотрудники',
    products: 'Продукты',
    countries: 'Страны',
    education: 'Обучение',
    softs: 'Программы',

    productsLinks: [
      {
        title: 'ДБО',
        link: 'https://bssys.com/products/business/d2go/',
      },
      {
        title: 'РЦК',
        link: 'https://bssys.com/holdings-and-corporations',
      },

      {
        title: 'Речевые технологии',
        link: 'https://bssys.com/speech-technology',
      },
      {
        title: 'Информационная безопасность',
        link: 'https://bssys.com/isecurity',
      },

      {
        title: 'ИТ-консалтинг',
        link: 'https://bssys.com/it-consulting',
      },
    ],

    button: {
      createProject: 'Создать проект',
      exit: 'Выход',
      feedback: 'Форма обратной связи',
    },
  },

  projectStatus: {
    all: 'все статусы',
    active: 'активно',
    pending: 'контракт',
    closed: 'отклонено',
    expired: 'закрыто',
    inProcess: 'на согласовании',
  },

  toastNotifications: {
    featureDate: 'Дата подачи должна быть сегодня, либо в будущем',
    register:
      'Партнерский аккаунт успешно создан! Вы сможете воспользоваться им после одобрения администратором',
    applied: 'Изменения успешно сохранены!',
    cityCreated: 'Страна создана',
    cityUpdated: 'Страна обновлена',
    docCreated: 'Релиз создан',
    weRBuildingTheSheet: 'Не закрывайте страницу, формируем документ',
    tableRReady: 'Таблица готова',

    newsUpdated: 'Новость изменена',
    newsCreated: 'Новость создана',
    partnerChanged: 'Партнер изменен',

    projectUpdated: 'Проект изменен',
    projectCreated: 'Проект создан',

    materialCreated: 'Материал добавлен',
    materialEdited: 'Материал изменен',

    employeeUpdated: 'Сотрудник изменен',
    employeeCreated: 'Сотрудник создан',
    releaseCreated: 'Релиз создан',
  },

  validation: {
    required: 'Поле обязательно',
    captchaRequired: 'Необходимо пройти капчу',
    email: 'Проверьте адрес эл. почты',
    emailTaken: 'Адрес эл. почты занят',
    wrongEmail: 'Неверный E-Mail',

    authWrongCredentials: 'Неверный email или пароль',
    authUpdateRejected: 'Ошибка обновления',
  },

  materialType: {
    ads: 'Рекламные',
    tech: 'Технические',
    sales: 'Для продаж',
    partnership: 'Партнерская программа',
    cases: 'Кейсы',
  },

  calendar: {
    month: 'Месяц',
    quarter: 'Квартал',
    year: 'Год',
    custom: 'Произвольно',

    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],

    normalDays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],

    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],

    monthsShort: [
      'Янв',
      'Фев',
      'Март',
      'Апр',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек',
    ],
  },
};
