import { makeStyles, styled } from "@material-ui/core";
import { t } from "i18next";
import { UnionIcon } from "../../components/core/icons/union";
import config from "../../app.config";
import ErrorHandler from "../errorHandler";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        margin: '2rem auto',
        width: '100%',
        maxWidth: '700px',
        padding: '1rem 2rem 3rem',
        borderRadius: '0.5rem',
        background: '#ffffff',
    },
    buttonLink: {
        color: '#fff',
        backgroundColor: '#201765',
        textDecoration: 'none',
        padding: '1rem 2rem',
        fontFamily: "Proxima Nova Lt",
        fontWeight: 600,
        borderRadius: '4px',
        display: 'inline-block',
        width: '100%',
        maxWidth: '300px',
        textAlign: 'center',

        '&:hover': {
            backgroundColor: '#211775',
        },
    },
    buttonIcon: {
        marginLeft: '0.5rem',
    }
}));

const StyledUnionIcon = styled(UnionIcon)({
    width: 12,
    height: 12,
});

export const Education: React.FC = () => {

    const classes = useStyles();

    if (config?.service === "spitch") {
        return <ErrorHandler error='404' />
    }

    return <div className={classes.root}>

        <h1>{t('header.education')}</h1>
        <p>{t('page.education.info')}</p>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            alignItems: 'center',
        }}>
            <a
                href='https://forms.yandex.ru/u/673765dde010dbdd33341a0f/'
                target='_blank'
                className={classes.buttonLink}
                rel='noreferrer'
            >
                <span>{t('page.education.button.engineers')}</span>
                <StyledUnionIcon className={classes.buttonIcon} width={'16px'} />
            </a>

            <a
                href='https://forms.yandex.ru/u/675177d5493639995bf69f2a/'
                target='_blank'
                className={classes.buttonLink}
                rel='noreferrer'
            >
                <span>{t('page.education.button.managers')}</span>
                <StyledUnionIcon className={classes.buttonIcon} width={'16px'} />
            </a>
        </div>

        <p style={{ marginBottom: '4rem' }}>
            {t('page.education.result')}<br />
            {t('page.education.question')} - <a href="mailto:partners@bssys.com">partners@bssys.com</a>
        </p>

        <p>{t('page.education.note')}</p>

        <a
            href='/materials/tech'
            className={classes.buttonLink}
            style={{ display: 'block', margin: '0 auto' }}
            rel='noreferrer'
        >
            <span>{t('page.education.button.materials')}</span>
            <StyledUnionIcon className={classes.buttonIcon} width={'16px'} />
        </a>

    </div>
}

export default Education;